var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-card-title",
            [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _vm.bundleType === _vm.distributionReasons.ASSIGNMENT
                      ? _c(
                          "span",
                          { staticClass: "secondary--text font-weight-bold" },
                          [_vm._v("Assignments")]
                        )
                      : _c(
                          "span",
                          { staticClass: "secondary--text font-weight-bold" },
                          [_vm._v("Distributions")]
                        ),
                    _c(
                      "v-btn",
                      {
                        attrs: { loading: _vm.fetchingSpaceBundles, icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$store.dispatch(
                              "spaceStore/fetchSpaceBundles",
                              _vm.$route.params.sid
                            )
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("refresh")])],
                      1
                    ),
                  ],
                  1
                ),
                _vm.fetchingSpaceBundles
                  ? _c("span", { staticClass: "caption text-uppercase" }, [
                      _vm._v("Fetching data..."),
                    ])
                  : _c("span", { staticClass: "caption text-uppercase" }, [
                      _vm._v(
                        " " + _vm._s(_vm.filteredBundlesByType.length) + " "
                      ),
                      _vm.bundleType === _vm.distributionReasons.ASSIGNMENT
                        ? _c("span", [_vm._v("Assignments")])
                        : _c("span", [_vm._v("Distributions")]),
                      _vm._v(" found "),
                    ]),
              ]),
              _c("v-spacer"),
              !_vm.assignmentsExist
                ? _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "secondary" },
                      on: { click: _vm.startAssignmentTour },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Create a new assignment"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.filteredBundlesByType.length && !_vm.fetchingSpaceBundles
            ? _c(
                "div",
                [
                  _c("v-data-iterator", {
                    attrs: { items: _vm.filteredBundlesByType },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return _vm._l(props.items, function (item, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  index > 0
                                    ? _c("v-divider", {
                                        staticClass: "mb-2",
                                        staticStyle: {
                                          opacity: "0.25",
                                          "border-width": "thin",
                                        },
                                        attrs: { inset: "" },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "v-list-item",
                                    { attrs: { "two-line": "" } },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { align: "center" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "1" } },
                                            [
                                              _c(
                                                "v-list-item-avatar",
                                                [
                                                  item.bundle_type_name ===
                                                  _vm.distributionReasons
                                                    .ASSIGNMENT
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-large": "",
                                                          },
                                                        },
                                                        [_vm._v("assignment")]
                                                      )
                                                    : _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-large": "",
                                                          },
                                                        },
                                                        [_vm._v("inventory")]
                                                      ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center",
                                                      },
                                                      [
                                                        _c("v-hover", {
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  hover,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        class: [
                                                                          hover
                                                                            ? "secondary--text"
                                                                            : "secondary--text",
                                                                          "subtitle-1",
                                                                          "font-weight-bold",
                                                                          "text-decoration-none",
                                                                        ],
                                                                        style:
                                                                          hover
                                                                            ? "cursor: pointer;"
                                                                            : "",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.gotoAssignment(
                                                                                item.bid
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.long_id
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                        !_vm.isMasterInstance &&
                                                        item.handins &&
                                                        _vm.bundleType ===
                                                          _vm
                                                            .distributionReasons
                                                            .ASSIGNMENT
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ml-1",
                                                              },
                                                              [
                                                                item.handins
                                                                  .length
                                                                  ? _c(
                                                                      "v-chip",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "success",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Submitted"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm.isAssignmentOverdue(
                                                                      item.allow_handins_until
                                                                    )
                                                                  ? _c(
                                                                      "v-chip",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "warning",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Overdue"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "v-chip",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "orange",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Submission due"
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]),
                                                  _c("v-list-item-subtitle", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-column subtitle-2",
                                                      },
                                                      [
                                                        item.description
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "subtitle-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.description
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        item.creation_timestamp
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mt-2 caption",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Created on"
                                                                  ),
                                                                ]),
                                                                _vm._v(
                                                                  " : " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dateTimeToHuman"
                                                                      )(
                                                                        item.creation_timestamp
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        item.allow_handins_until
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "caption",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Hand-in deadline"
                                                                  ),
                                                                ]),
                                                                _vm._v(
                                                                  " : " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dateTimeToHuman"
                                                                      )(
                                                                        item.allow_handins_until
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        item.handins &&
                                                        item.handins.length &&
                                                        !_vm.isMasterInstance
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "caption",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Last submission on " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "dateTimeToHuman"
                                                                        )(
                                                                          item
                                                                            .handins[0]
                                                                            .handin_timestamp
                                                                        )
                                                                      )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-action", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "d-flex align-center",
                                          },
                                          [
                                            _vm.bundleType ===
                                              _vm.distributionReasons
                                                .ASSIGNMENT &&
                                            !_vm.isAssignmentOverdue(
                                              item.allow_handins_until
                                            ) &&
                                            !_vm.isMasterInstance
                                              ? _c(
                                                  "TheSnapshotAssignmentSubmitDialog",
                                                  {
                                                    attrs: { bundleData: item },
                                                  }
                                                )
                                              : _vm._e(),
                                            _vm.isSpaceAdmin
                                              ? _c("BundleDeleteDialog", {
                                                  attrs: { bundleData: item },
                                                })
                                              : _vm._e(),
                                            _vm.isSpaceAdmin &&
                                            _vm.bundleType ===
                                              _vm.distributionReasons.ASSIGNMENT
                                              ? _c("BundleEditDialog", {
                                                  attrs: { bundleData: item },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            })
                          },
                        },
                        {
                          key: "no-results",
                          fn: function () {
                            return [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "mt-3",
                                  attrs: {
                                    value: true,
                                    color: "error",
                                    icon: "warning",
                                    text: "",
                                  },
                                },
                                [_vm._v("Your search for found no results.")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3660023205
                    ),
                  }),
                ],
                1
              )
            : !_vm.filteredBundlesByType.length && !_vm.fetchingSpaceBundles
            ? _c(
                "div",
                [
                  _c(
                    "v-alert",
                    { attrs: { text: "", prominent: "", type: "info" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center justify-space-between w-100",
                        },
                        [
                          _vm.bundleType === _vm.distributionReasons.ASSIGNMENT
                            ? _c("div", { staticClass: "d-flex flex-column" }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("No assignments found")]
                                ),
                                _c("span", [
                                  _vm._v(
                                    " So far no assignments have been created. "
                                  ),
                                  _vm.isSpaceAdmin
                                    ? _c("span", [
                                        _vm._v(
                                          " You can create new assignments by staging and sharing objects (files, tables, and applications) as an assignment. "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ])
                            : _c("div", { staticClass: "d-flex flex-column" }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("No saved distributions")]
                                ),
                                _c("span", [
                                  _vm._v(
                                    " So far no object distributions have been saved. You can create new bundles by staging and sharing objects (files, tables, and applications) and saving them as public / private bundles. "
                                  ),
                                ]),
                              ]),
                          _vm.isSpaceAdmin &&
                          _vm.bundleType === _vm.distributionReasons.ASSIGNMENT
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    href: "https://docs.nuvolos.cloud/user-guides/education-guides/setting-assignments",
                                    target: "_blank",
                                    outlined: "",
                                    small: "",
                                    color: "info",
                                  },
                                },
                                [_vm._v(" Learn more ")]
                              )
                            : _vm.bundleType ===
                              _vm.distributionReasons.MATERIAL_SHARING
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    href: "https://docs.nuvolos.cloud/getting-started/nuvolos-basic-concepts/distribution",
                                    target: "_blank",
                                    outlined: "",
                                    small: "",
                                    color: "info",
                                  },
                                },
                                [_vm._v(" Learn more ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm.fetchingSpaceBundles
            ? _c(
                "div",
                _vm._l(
                  [
                    { id: "c1", opacity: 1 },
                    { id: "c2", opacity: 0.75 },
                    { id: "c3", opacity: 0.5 },
                  ],
                  function (item) {
                    return _c("div", { key: item.id, staticClass: "my-6" }, [
                      _c(
                        "div",
                        { style: { opacity: item.opacity } },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: { type: "list-item-avatar-two-line" },
                          }),
                        ],
                        1
                      ),
                    ])
                  }
                ),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }